import ModularRocks from '@modular-rocks/core'
const tools = ModularRocks.tools
const { pieces2Path, path2Pieces } = tools

const isObject = (keys) => keys.length > 0
const findType = (keys) => isObject(keys) > 0 ? 'object' : 'property'
const buildPath = (name, paths) => {
  const pieces = path2Pieces(paths)
  pieces.push(name)
  return pieces2Path(pieces)
}

const build = (scope, _children, paths) => {
  return _children.map((name) => {
    const keys = Object.keys(scope[name] || {})
    const path = buildPath(name, paths)
    const type = findType(keys)
    const children = build(scope[name], keys, path)
    return { name, path, type, keys, children, _o: scope[name] }
  })
}

export default function(scope) {
  const types = Object.keys(scope);
  const children = build(scope, types, 'scope')
  return {name: 'scope', types, type: 'object', children}
}
