import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Overview from '../images/overview.jpeg'

function Hero2() {

  const [height, setHeight] = useState(0)

  const scrollABit = () => {
    window.scrollTo({
      top: window.pageYOffset + 400, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  const onImgLoad = ({target}) => {
    const height = target.height;
    setHeight(height)
    // this.setState({
    //   width: img.width,
    //   height: img.height,
    // });
  };

  return (
    <>
      <section className="">
        <div className="grid max-w-screen-xl px-1 py-1 mx-auto lg:gap-8 xl:gap-0 lg:py-1 lg:grid-cols-12">
            <div className="bg-black-transparent main-info px-8 py-4 lg:py-16 mr-auto place-self-center lg:col-span-7">
                <h1 className="white max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                  Automatic Modularisation
                </h1>
                <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                  Modularise your codebase, automatically<br/>
                </p>
                <a href="https://github.com/modular-rocks" className="modular-rocks-blue-btn inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border rounded-lg bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  Github
                </a> 
                <a href="/docs/start" className="modular-rocks-pink-btn ml-2 mr-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border rounded-lg bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  Docs
                </a> 
                {/* <a href="/platform" className="modular-rocks-pink-btn ml-2 mr-2 inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border rounded-lg bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                  Platform
                </a>  */}
                {/* <a onClick={scrollABit} className="cursor-pointer inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                    Scroll to learn more
                    <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a> */}
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            </div>                
        </div>
      </section>
      {/* <section className="overview-fixed">
        <img src={Overview} alt="mockup" />
      </section>
      <section className="overview-see-through-alt">
        <img src={Overview} alt="mockup" />
      </section> */}
      {/* <section className="overview-see-through">
      </section> */}
    </>
  );
}

export default Hero2;


