import React from 'react'
import ModularRocks from '@modular-rocks/core'
import File from './components/File.jsx'
import Folder from './components/Folder.jsx'
import ModulesToTree from './lib/modules-to-tree.js'
import './styles.css'

const tools = ModularRocks.tools

const { path2Pieces, pieces2Path } = tools


const to_i = (x, i) => [x, i]
const exists = (factories) => ([x]) => factories[x]
const find = function(path, factories) {
  const pieces = path2Pieces(path)
  const filtered = pieces.map((x, i) => [x, i]).filter(exists(factories))
  if (!filtered.length) return path
  const index = filtered.reverse()[0][1]
  return `/${pieces2Path(pieces.slice(0,index))}/`
}

const compare = function(scope, highlighted, opts) {
  if (!highlighted) return false
  const factories = {}
  opts.factories.map((x) => {
    const k = x.pathname || x
    factories[k] = k
  })

  const foundScope = find(scope, factories)
  const foundHighlighted = find(highlighted, factories)

  const matched = foundHighlighted.match(foundScope)
  const matched2 = scope.match(highlighted)

  return ((foundScope == foundHighlighted) || (matched && matched.length > 0)) || (matched2 && matched2.length > 0)
}

const LoopDirectory = function(props) {
  const children = props.data.sort((a, b) => {
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0;
  })
  return children.map((_props, index) => {
    const active = compare(_props.scope, props.highlighted, props.opts)
    if (_props.type == 'file') {
      return (
        <File key={index} {..._props} open={props.open} active={active} highlighted={props.highlighted} dimming={props.dimming} semantic={props.semantic} />
      )
    }

    if (_props.type == 'folder') {
      return (
        <Folder {..._props} key={index} open={props.open} active={active} highlighted={props.highlighted} dimming={props.dimming} semantic={props.semantic}>
          <LoopDirectory
            data={_props.children}
            open={props.open}
            highlighted={props.highlighted}
            opts={props.opts}
            dimming={props.dimming}
            semantic={props.semantic}
          />
        </Folder>
      )
    }
  })
}

export default function({data, open, opts, scope, highlighted, dimming, semantic}) {
  return (
    <div id='treeview-container'>
      <div className='top-shadow'></div>
      <div id='treeView'>
        <section className="container">
          <LoopDirectory
            data={ModulesToTree(data, opts)}
            open={open}
            scope={scope}
            highlighted={highlighted}
            opts={opts}
            dimming={dimming}
            semantic={semantic}
          />
        </section>
      </div>
      <div className='bottom-shadow'></div>
    </div>
  )
}
