import React from 'react'
import ModularRocks from '@modular-rocks/core'
import { CodeBracketIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

const tools = ModularRocks.tools
const { pieces2Path, path2Pieces } = tools

const find = ([path, fn], reference) => {
  let pathname = path2Pieces(path).reverse()[0]
  pathname = pathname.replace('.jsx', '').replace('.js', '')
  const [name, ex] = pathname.split('.')

  if (!ex) {
    return []
  }

  const value = reference.filter(([_path]) => new RegExp(ex + '.js', 'gi').test(_path))[0]

  let [exPath, exName] = value
  exName = path2Pieces(exPath).reverse()[0].replace('.js', '')

  return [
    exPath,
    exName
  ]

}

export default function(props) {
  const { name, open, _o, reference, scope } = props
  const onClick = () => {
    const value = reference.filter(([path, fn]) => fn === _o)[0]
    const [exPath, exName] = find(value, reference)
    open(value[0], exPath, exName, scope)
  }
  return (
    <div className="property" onClick={onClick}>
      <span>
        <ChevronRightIcon className="h-5 w-5 text-blue-500 inline-block" 
                        style={{color: '#6eb7d6'}} />
        <span>
          {name}
        </span>
      </span>
    </div>
  )
};
