import React, { useState, useRef, useEffect } from 'react';

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}
function eraseCookie(name) {   
  document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const cookie = getCookie('gdpr-accept')

function Cookies() {
  const [hide, hideCookie] = useState(cookie)
  if (hide) return false
  const set = () => {
    setCookie('gdpr-accept', true, 365)
    hideCookie(true)
  }
  return (
    <div className="container cookies px-20">
      <div style={{backgroundColor: 'rgb(255, 255, 255)'}}>
        <div className="w-72 bg-white rounded-lg shadow-md p-6" style={{cursor: 'auto'}}>
          <div className="w-16 mx-auto relative -mt-10 mb-3">
            <img className="-mt-1" src="https://www.svgrepo.com/show/30963/cookie.svg" alt="Cookie Icon SVG" />
          </div>
          <span className="w-full sm:w-48 block leading-normal text-gray-800 text-md mb-3">
            We use cookies, are you ok with that?
          </span>
          <div className="flex items-center justify-between">
            <a className="text-xs text-gray-400 mr-1 hover:text-gray-800" href="/privacy">Privacy Policy</a>
            <div className="w-1/2">
              <button 
                onClick={set}
                type="button" 
                className="py-2 px-4  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
