import React from 'react';
import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/Features';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Testimonials from '../partials/Testimonials';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import MainDemo from '../partials/MainDemo';
import Core from '../partials/Core';
import Bit from '../partials/Bit';
import Hero2 from '../partials/Hero2';
import ModularOutsourcing from '../partials/ModularOutsourcing';
import Features2 from '../partials/Features2';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />
      <main className="flex-grow">
        <Hero2 />
      </main>


      {/*  Page content */}
      <main className="flex-grow bg-black">
        {/* <ModularOutsourcing />
        <Core />
        <MainDemo />
        <Features2 /> */}
        {/* <FeaturesHome /> */}
        {/* <FeaturesBlocks /> */}
        {/* <Testimonials /> */}
        <Newsletter />
      </main>

      {/*  Site footer */}
      {/* <Footer /> */}

    </div>
  );
}

export default Home;
