import ModularRocks from '@modular-rocks/core'
import Bundler from './bundler.js'
import find from './find-scope.js'

const features = ModularRocks.features
const { prepare, wrap } = features

const decorators = ModularRocks.decorators
const { add } = decorators

const addEntension = (fns, opts) => ([str, path]) => {
  const fn = fns[path]
  opts.extensionStrings.push([str, fn, path])
  add(str, fn)
}

const toFunctions = function(data, scopes) {
  const fns = {}
  const reference = []
  Object.keys(data).map((path) => {
    scopes[path]= {}
    const newStr = data[path].replace('export default', `const scope = window.rocksScopes['${path}']; return`)
    fns[path] = new Function(newStr)()
    reference.push([path, fns[path]])
  })
  return { fns, reference }
}

const toRocks = function(bundler, opts={}, scopes) {
  opts.bundler = bundler
  const collection = bundler.keys()
  const { refined } = prepare(collection, opts)
  const wrapped = wrap(refined, opts)
  Object.keys(scopes).map((k) => {
    if (!/\/app\//i.test(k)) return false
    const { scope, scopePath } = find(wrapped, k)
    const keys = Object.keys(scope);
    keys.map((x) => {
      scopes[k][x] = scope[x]
    })
  })
  return wrapped;
}


export default function(Data, opts) {
  const { exs } = opts

  const scopes = window.rocksScopes = {}
  opts.extensionStrings = []
  opts.extensions = {}

  const { fns, reference } = toFunctions(Data, scopes)
  Bundler.batch(fns)
  exs.map(addEntension(fns, opts))
  const rocks = toRocks(Bundler, opts, scopes)

  delete window['rocksScopes']

  return { scopes, rocks, reference }
}
