import React, { useState, useRef, useEffect } from 'react';
import { FolderIcon, FireIcon, UserGroupIcon, ScaleIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Faster Development',
    description:
      'Modularisation starts during project scoping, for development in parallel, less internal hiring saves time',
    icon: FireIcon,
  },
  {
    name: 'Access to experts',
    description:
      'Scope tiny pieces of work and then shift the implementation to developers with domain experience',
    icon: UserGroupIcon,
  },
  {
    name: 'Smarter & Scalable',
    description:
      'Structured code is smarter & scalable; in a similar way to Typescript, your code should know more about itself than developers',
    icon: ScaleIcon,
  },
  {
    name: 'Simpler Refactoring',
    description:
      'Use folders for what they were made for - deeply nest code without the complexity with the help of scopes',
    icon: FolderIcon,
  }
]

export default function Example() {
  return (
    <div className="bg-black py-24 sm:py-32 lg:py-40">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mt-20 max-w-lg sm:mx-auto md:max-w-none">
          <div className="grid grid-cols-1 gap-y-16 md:grid-cols-2 md:gap-x-12 md:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative flex flex-col gap-6 sm:flex-row md:flex-col lg:flex-row">
                <div className="flex h-12 w-12 items-center justify-center rounded-xl bg-indigo-500 text-white sm:shrink-0">
                  <feature.icon className="h-8 w-8" aria-hidden="true" />
                </div>
                <div className="sm:min-w-0 sm:flex-1">
                  <p className="text-lg font-semibold leading-8 text-white">{feature.name}</p>
                  <p className="mt-2 text-base leading-7 text-gray-600">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
