import React from 'react';

function Newsletter() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          <div className="relative bg-gray-900 rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden" data-aos="zoom-y-out">
            <div className="relative flex flex-col lg:flex-row items-center justify-center">
              {/* CTA content */}
              <div className="text-center lg:text-left lg:max-w-xl">
                <h3 className="h3 text-white mb-2">Let's stay in touch</h3>
                <p className="text-gray-300 text-lg mb-6">
                  Simple, modular, connected code.
                </p>

                {/* CTA form */}
                <form className="w-full lg:w-auto" action="https://rocks.us5.list-manage.com/subscribe/post?u=b828f4aff0314c03330da4fb5&amp;id=de61674a48" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                  <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:mx-0">
                    <input type="email" name="EMAIL" id="mce-EMAIL" required className="w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Your email…" aria-label="Your email…" />
                    <button type='submit' className="btn text-white bg-blue-600 hover:bg-blue-700 shadow">Subscribe</button>
                  </div>
                  <div style={{'position': 'absolute', 'left': '-5000px'}} aria-hidden="true">
                    <input type="text" name="b_b828f4aff0314c03330da4fb5_de61674a48" tabIndex="-1" />
                  </div>
                  {/* Success message */}
                  {/* <p className="text-sm text-gray-400 mt-3">Thanks for subscribing!</p> */}
                  {/*<p className="text-sm text-gray-400 mt-3">7 days free trial. No credit card required.</p>*/}
                </form>
                <div className="py-4">
                  <p className='text-white'>
                    <a href='http://m.me/modular.rocks/' target='_blank'>
                      or chat with us on Facebook here
                    </a>
                  </p>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Newsletter;
