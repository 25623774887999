import React, { useEffect } from 'react';

function ModularOutsourcing(props) {
  return (
    <section className="bg-black">
      <div class='modular-outsourcing-video'>
        <div>
          <div className="mx-10 text-center pb-12 md:pb-16">
            <h1 className="h2 mb-4">What is Modular Outsourcing?</h1>
            <p className="text-xl text-gray-600 my-10">
              Distributing structured code to external developers, without giving full access to your codebase.
            </p>
          </div>
          <div>
            <div className="relative flex justify-center mb-8" data-aos="zoom-y-out" data-aos-delay="450">
              <div className="flex flex-col justify-center" style={{'width': '100%'}}>
                <img style={{'width': '100%', 'margin': 'auto', 'display': 'block'}}
                  className="vidyard-player-embed"
                  src="https://play.vidyard.com/ZunMz49jrxbMhWjZqKrgMe.jpg"
                  data-uuid="ZunMz49jrxbMhWjZqKrgMe"
                  data-v="4"
                  data-type="inline"
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ModularOutsourcing;
