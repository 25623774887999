import ModularRocks from '@modular-rocks/core'
const tools = ModularRocks.tools
const { parentScopePath } = tools

const rootPaths = [undefined, '//', '/']
const shouldRespondWithRoot = (scopePath) => rootPaths.includes(scopePath)
const shouldFindParent = (scope) => scope === undefined

const find = function(scopes, scopePath) {
  if (shouldRespondWithRoot(scopePath)) {
    return { scope: scopes.root, scopePath }
  }

  const scope = scopes[scopePath]
  if (shouldFindParent(scope)) {
    return find(scopes, parentScopePath(scopePath))
  }

  return { scope, scopePath }
}
export default find
