import ModularRocks from '@modular-rocks/core'
const tools = ModularRocks.tools

let store = {}
let mapped = []

const resolve = (path, opts) => {
  return {
    scopedPath: path,
    fullpath: path
  }
}

const addFullPath = (path, filename) => tools.clean([path, filename].join('/'))
const load = (meta, metadata, env) => {
  const filepath = addFullPath(metadata.absolutePath, meta.filename)
  const fn = store[filepath]

  if (!fn) {
    throw Error('Filename not found at ' + filepath)
  }
  return fn.default || fn
}

const batch = (obj) => {
  store = {}
  mapped = []
  Object.keys(obj).map((k) => {
    mapped.push([k, obj[k]])
    store[k] = obj[k]
  })
}

export default {
  resolve,
  load,
  batch,
  store,
  mapped,
  get: (key) => store[key],
  set: (key, value) => store[key] = value,
  keys: () => Object.keys(store),
  reset: () => store = {}
}
