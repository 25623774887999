import ModularRocks from '@modular-rocks/core'
const tools = ModularRocks.tools

const findChild = ({ children, piece }) => children.filter((x) => x.name === piece)[0]
const prepType = (type) => type.pathname || type
const compareType = (piece) => (type) => prepType(type) == piece
const locateFactory = ({ opts, piece }) => opts.factories.filter(compareType(piece)).length
const filterExtensions = (name) => ([ex]) => new RegExp('.' + ex, 'gi').test(name)
const addFile = ({ children, piece, path, opts }, extension) => {
  const scope = path.replace(piece, '')
  children.push({ name: piece, type: 'file', path, scope, extension })
}

const addFolder = (config, extension) => {
  let child = findChild(config)
  const scope = `/${tools.pieces2Path(config.collection)}/`
  const isFactory = locateFactory(config);
  if (!child) {
    child = {
      name: config.piece,
      type: 'folder',
      children: [],
      isFactory,
      scope,
      isScopeSection: config.isScopeSection,
      extension
    }
    config.children.push(child)
  }
  config.children = child.children

  if (config.isScopeSection) {
    config.isScopeSection = false
  }

  if (isFactory) {
    config.isScopeSection = true
  }

  config.isFactory = isFactory

  configure(config)
}

const configure = (config) => {
  config.piece = config.pieces.shift();
  config.collection.push(config.piece)
  const isFile = /\.js?/i.test(config.piece)
  let extension = config.opts.exs.filter(filterExtensions(config.piece))[0]
  isFile ? addFile(config, extension) : addFolder(config, extension)
}

export default (data, opts) => {
  const children = []
  const paths = Object.keys(data);

  paths.map((path) => {
    const pieces = tools.path2Pieces(path);
    configure({
      pieces,
      path,
      opts,
      collection: [],
      children,
      isFactory: false,
      isScopeSection: false
    });
  })

  return children
}
